<div class="lang-select-container">
  <button type="button"
          role="button"
          class="btn-lang"
          [ngClass]="{'active': activeLanguage === 'sv' || activeLanguage === 'no'}"
           (click)="changeLanguage(nativeLanguage.iso6391)">{{ nativeLanguage.iso6392 }}</button>
  <div class="divider"></div>
  <button type="button"
          role="button"
          class="btn-lang"
          [ngClass]="{'active': activeLanguage === 'en'}"
          data-lang="en"
          (click)="changeLanguage('en')">ENG</button>
</div>
