<div class="candidate-register-wrapper">
  <h2>{{ 'REGISTER.GREETING' | translate }}</h2>
  <p>{{ 'REGISTER.EXPLANATION' | translate }}</p>
  <form [formGroup]="candidateRegisterForm"
        (ngSubmit)="onSubmit()">

    <div class="form-group form-input-wrapper">
      <input type="text"
             class="form-control"
             formControlName="name"
             placeholder="{{ 'REGISTER.LABELS.NAME' | translate }}">
      <div class="invalid-feedback" *ngIf="!name.valid && name.touched">
        <div *ngIf="name.errors?.required">
          {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
        </div>
      </div>
    </div>
    <div class="form-group form-input-wrapper">
      <input
        class="form-control"
        formControlName="email"
        placeholder="{{ 'REGISTER.LABELS.EMAIL' | translate }}">
      <div class="invalid-feedback" *ngIf="!email.valid && email.touched">
        <div *ngIf="email.errors?.required">
          {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
        </div>
        <div *ngIf="email.errors?.pattern">
          {{ 'SHARED.EMAIL_INVALID_ERROR_MSG' | translate }}
        </div>
      </div>
    </div>

    <div class="form-group mobile-phone-group">
      <select formControlName="networks"
              class="form-control custom-select"
              (change)="changedNetwork()">
        <option class="form-control"
                *ngFor="let network of countryCallingCodes"
                [ngValue]="network">
          {{network.callingCode}}
        </option>
      </select>
      <div class="phone-input">
        <input type="text"
               class="form-control"
               formControlName="phone"
               placeholder="{{ 'REGISTER.LABELS.PHONE' | translate }}">
        <div class="invalid-feedback" *ngIf="!phone.valid && phone.touched">
          <div *ngIf="phone.errors?.required">
            {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
          </div>
          <div *ngIf="phone.errors?.pattern">
            {{ 'SHARED.PHONE_ERROR_PATTERN_MSG' | translate }}
          </div>
          <div *ngIf="!phone.errors?.pattern && (phone.errors?.minlength || phone.errors?.maxlength)">
            <ng-container *ngIf="(phoneNumberMinLength !== 0) && (phoneNumberMinLength < phoneNumberMaxLength)">
              {{ 'SHARED.PHONE_ERROR_LENGTH_MSG' | translate: {minLength: phoneNumberMinLength, maxLength: phoneNumberMaxLength}  }}
            </ng-container>
            <ng-container *ngIf="phoneNumberMinLength === 0">
              {{ 'SHARED.PHONE_ERROR_LENGTH_MSG_MAX_LENGTH' | translate: {maxLength: phoneNumberMaxLength}  }}
            </ng-container>
            <ng-container *ngIf="phoneNumberMinLength === phoneNumberMaxLength">
              {{ 'SHARED.PHONE_ERROR_LENGTH_MSG_FIXED_LENGTH' | translate: {length: phoneNumberMaxLength}  }}
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group tos">
      <label class="custom-checkbox">
        <input type="checkbox"
               [checked]="false"
               formControlName="termsAndConditions">
        <i class="checkmark"></i>
      </label>
      <p>
        {{ 'REGISTER.AGREE_TO_HIGHER' | translate }}
        <a class="open-modal-link" (click)="showHideModal()">{{ 'REGISTER.TOS' | translate }}</a>
      </p>

    </div>

    <div class="btn-group">
      <button type="submit"
              class="btn-secondary"
              [disabled]="candidateRegisterForm.invalid">
        {{ 'BUTTONS.START' | translate }}
      </button>
    </div>
  </form>
</div>

<app-modal-window [modalIsShown]="modalIsShown"
                  (hideModalWindow)="showHideModal()">
  <h3 class="modal-window-title">
    {{ 'REGISTER.TOS' | translate }}
  </h3>
  <div class="modal-window-content">
    <quill-view-html [content]="termsAndConditions"></quill-view-html>
  </div>
  <div class="modal-footer-content">
    <button type="button"
            class="btn-secondary"
            (click)="iAgree()">
      {{ 'BUTTONS.AGREE' | translate }}
    </button>
  </div>
</app-modal-window>
