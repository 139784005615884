import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, tap, catchError } from 'rxjs/operators';
import { INITIAL_JOB_FILTERS, JobFilters } from '../models/filters.model';
import { Job, JobLocation, JobTimeExtent } from '../models/job.model';
import { AppConfigService } from './app-config.service';
import { Router } from '@angular/router';
import { ErrorHandlingService } from './error-handling.service';
import { Pagination } from '../models/pagination.interface';
import { JobCategory } from '../models/job-category.model';
import { JobApplication } from '../models/job-application.model';
import { SavedJobsService } from './saved-jobs.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedFunctionsService } from './shared-functions.service';
import { LocalStorageService } from './storage/local-storage.service';
import { CountryCallingCode } from '../models/country-calling-codes.model';
import { DocumentService } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private _searchBy: string = null;
  private _category: string = null;
  private _location: string = null;
  private _typeOfEmployment: string = null;
  private _timeExtent: string = null;
  private _filtersUpdated$: BehaviorSubject<JobFilters> = new BehaviorSubject<JobFilters>(INITIAL_JOB_FILTERS);

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private savedJobsService: SavedJobsService,
    private router: Router,
    private errorHandlingService: ErrorHandlingService,
    private translateService: TranslateService,
    private localStorage: LocalStorageService,
    private sharedFunctions: SharedFunctionsService,
    private document: DocumentService
  ) {
    const filters = this.getFilters();
    if (filters) {
      this._filtersUpdated$.next(filters);
    }
  }

  get filtersUpdated$(): Observable<JobFilters> {
    return this._filtersUpdated$.asObservable();
  }

  get category(): string {
    return this._category;
  }

  set category(id: string) {
    this._category = id;
    this.saveFiltersToLocalStorage('category', this._category);
    this._filtersUpdated$.next(this.getFilters());
  }

  get location(): string {
    return this._location;
  }

  set location(id: string) {
    this._location = id;
    this.saveFiltersToLocalStorage('location', this._location);
    this._filtersUpdated$.next(this.getFilters());
  }

  get searchBy(): string {
    return this._searchBy;
  }

  set searchBy(term: string) {
    this._searchBy = term;
    this.saveFiltersToLocalStorage('searchBy', this._searchBy);
    this._filtersUpdated$.next(this.getFilters());
  }

  get typeOfEmployment(): string {
    return this._typeOfEmployment;
  }

  set typeOfEmployment(id: string) {
    this._typeOfEmployment = id;
    this.saveFiltersToLocalStorage('typeOfEmployment', this._typeOfEmployment);
    this._filtersUpdated$.next(this.getFilters());
  }

  get timeExtent(): string {
    return this._timeExtent;
  }

  set timeExtent(id: string) {
    this._timeExtent = id;
    this.saveFiltersToLocalStorage('timeExtent', this._timeExtent);
    this._filtersUpdated$.next(this.getFilters());
  }

  private saveFiltersToLocalStorage(filter: string, value: string): void {
    const filters = this.localStorage.getItem('filters');
    if (filters) {
      this.localStorage.setItem('filters', { ...filters, [filter]: value });
    } else {
      this.localStorage.setItem('filters', { [filter]: value });
    }
  }

  updateFilters(filters: JobFilters): void {
    Object
      .keys(filters)
      .forEach((filterKey: string) => this.saveFiltersToLocalStorage(filterKey, filters[filterKey]));
    this._filtersUpdated$.next(filters);
  }

  getFilters(): JobFilters {
    const filters = this.localStorage.getItem('filters');

    if (filters) {
      Object
        .keys(filters)
        .forEach((filterKey: string) => {
          if (filters[filterKey] === 'null') {
            filters[filterKey] = null;
          }
        });
      return filters;
    } else {
      return { category: null, typeOfEmployment: null, location: null, searchBy: null };
    }
  }

  getCategories(): Observable<JobCategory[]> {
    const companyId = this.configService.company.id;
    let params = new HttpParams();
    params = params.set('limit', '1000');
    params = params.set('company', companyId.toString());

    return this.http.get(`${environment.companyCategories}`, { params })
      .pipe(
        map(({data}: Pagination<JobCategory>) => data),
      );
  }

  getLocations(): Observable<JobLocation[]> {
    const companyId = this.configService.company.id;
    let params = new HttpParams();
    params = params.set('limit', '1000');
    params = params.set('company', companyId.toString());

    return this.http.get(`${environment.companyLocations}`, { params })
      .pipe(
        map(({data}: Pagination<JobLocation>) => data)
      );
  }

  getTypesOfEmployment(): Observable<JobTimeExtent[]> {
    const companyId = this.configService.company.id;
    let params = new HttpParams();
    params = params.set('limit', '1000');
    params = params.set('company', companyId.toString());

    return this.http.get(`${environment.companyTypesOfEmployment}`, { params })
      .pipe(
        map(({data}: Pagination<JobTimeExtent>) => data)
      );
  }

  // Unused method, check if endpoint exists and talk with backend to remove it if it's unnecessary
  // getTimeExtents() {
  //   let params = new HttpParams();
  //   params = params.set('limit', '1000');
  //   return this.http.get(`${environment.companyTimeExtents}`, { params })
  //     .pipe(
  //       map(({data}: Pagination<JobTimeExtent>) => data)
  //     );
  // }

  getJobs(filters: JobFilters): Observable<Pagination<Job>> {
    const companyGuid = this.configService.company.guid;
    const language = this.translateService.currentLang;
    const companyLanguage = this.configService.companyLanguage;

    let params = new HttpParams();
    if (filters.category) {
      params = params.set('category', filters.category);
    }
    if (filters.location) {
      params = params.set('location', filters.location);
    }
    if (filters.typeOfEmployment) {
      params = params.set('typeOfEmployment', filters.typeOfEmployment);
    }
    if (filters.searchBy) {
      params = params.set('searchBy', filters.searchBy);
    }
    if (filters.page) {
      params = params.set('page', filters.page);
    }

    params = params.set('limit', '6');
    params = params.set('publishOnHigher', '1');
    params = params.set('status', 'active');
    params = params.set('language', companyLanguage);

    return this.http
      .get(`${environment.companyJobs}/${companyGuid}/jobs`, {
        headers: new HttpHeaders({ 'Accept-language': language }),
        params
      })
      .pipe(
        tap(({data}: Pagination<Job>) => this.savedJobsService.mapSavedJobs(data))
      );
  }

  getJob(guid: string): Observable<Job> {
    return this.http.get(`${environment.job}/${guid}`).pipe(
      map(({data}: Pagination<Job>) => {
        const job = data[0];
        job.deadlineDate = this.sharedFunctions.convertStringDateToJSDate(job.applicationDate);

        return job;
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.router.navigate(['/jobs']);
        return this.errorHandlingService.handleBackendError(errorResponse);
      })
    );
  }

  sendSmsLink(guid: string, language: string): Observable<JobApplication> {
    const headers = new HttpHeaders({
      'Accept-Language': language
    });

    return this.http.put(`${environment.createApplication}/${guid}/send_application_link_sms`, null, { headers })
      .pipe(
        map(({data}: Pagination<JobApplication>) => data[0]),
      );
  }

  getCountryCallingCodes(): Observable<CountryCallingCode[]> {
    const origin = this.document.location.origin;
    return this.http.get<CountryCallingCode[]>(`${origin}/assets/country-calling-codes.json`);
  }
}
