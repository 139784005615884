import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// import cssVars from 'css-vars-ponyfill';
import 'hammerjs';

// cssVars({
//   onlyLegacy: true,
//   watch: true,
//   updateURLs: false,

// })

if (environment.production) {
  enableProdMode();
}

function bootstrap(): void {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
}


if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

