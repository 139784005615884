import { Component, Input } from '@angular/core';

export enum COLOR_TYPES {
  white = '#fff',
  grayLight = '#EFEFEF'
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  @Input() isFixed = false;
  @Input() backgroundColor = COLOR_TYPES.grayLight;
}
