<footer class="footer"
        [ngStyle]="{
          position: isFixed ? 'fixed' : 'unset',
          'background-color': backgroundColor
        }">
  <div class="footer-content">
    Hire with
    <img class="logo"
         alt="Higher logo"
         src="../../../../assets/images/higher-logo.svg" />
  </div>
</footer>
