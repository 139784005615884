<div class="container notranslate">
<!--     [ngClass]="{-->
<!--       'company': activePage === 'company',-->
<!--       'quiz': activePage === 'quiz',-->
<!--       'application': activePage === 'application',-->
<!--       'application-complete': activePage === 'application-complete'-->
<!--      }">-->
<!--  <app-header></app-header>-->
  <main [@routeAnimations]="prepareOutlet(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
  </main>
<!--  <div *ngIf="showSendLinkContainer"-->
<!--       class="send-link-container">-->
<!--    <button class="btn-link"-->
<!--            (click)="showHideSendLinkModal()">{{ 'SEND_LINK.SEND_LINK_BTN' | translate }}</button>-->
<!--  </div>-->
</div>
<!--<app-modal-window [modalIsShown]="showModal"-->
<!--                  (hideModalWindow)="showHideSendLinkModal()">-->
<!--  <h3 class="modal-window-title">-->
<!--    {{ 'SEND_LINK.MODAL.TITLE' | translate }}-->
<!--  </h3>-->
<!--  <div class="modal-window-content">-->
<!--    {{ 'SEND_LINK.MODAL.CONTENT' | translate }}-->
<!--  </div>-->
<!--  <div class="modal-footer-content">-->
<!--    <button (click)="showHideSendLinkModal()"-->
<!--            class="btn-secondary">{{ 'SEND_LINK.MODAL.CLOSE_MODAL_BTN' | translate }}</button>-->
<!--    <button (click)="sendLink()"-->
<!--            class="btn-primary">{{ 'SEND_LINK.MODAL.SEND_LINK_BTN' | translate }}</button>-->
<!--  </div>-->
<!--</app-modal-window>-->
<app-loader></app-loader>
