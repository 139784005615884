import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppConfigService } from '../services/app-config.service';
import { finalize, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppGuard implements CanActivate {

  constructor(
    private configService: AppConfigService,
  ) {}

  canActivate(): Observable<boolean> {
    return this.configService.initialize();
  }
}
