import { Inject, Injectable, Injector, Optional, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Quiz } from '../models/quiz.model';
import { Company } from '../models/company.model';
import { IApplication } from '../models/application.model';
import { PersonalityTest, PersonalityTestAnswers, PersonalityTestResults } from '../models/personality-test.model';
import { Pagination } from '../models/pagination.interface';
import { Application } from '../classes/application.class';
import { TranslateService } from '@ngx-translate/core';
import { SharedFunctionsService } from './shared-functions.service';
import { DocumentService } from './window.service';
import { Router } from '@angular/router';
import { APP_BASE_HREF, isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable({
  providedIn: 'root',
})
export class SetupService {

  protected _companyDomain: string;

  constructor(
    private http: HttpClient,
    protected translateService: TranslateService,
    protected sharedFunctions: SharedFunctionsService,
    protected document: DocumentService,
  ) {}

  setCompanyDomain(): void {
    const hostName: string = this.document.location.hostname || 'localhost';

    if (hostName === 'localhost' || hostName.includes('192.168.')) {
      this._companyDomain = 'demo';
      return;
    }

    const externalDomain = environment.externalDomains
      .find(dom => hostName.includes(dom.externalDomain));

    if (externalDomain) {
      this._companyDomain = externalDomain.companyDomain;
      return;
    }

    const domain: string[] = hostName.split('.');

    if (domain[0] === 'www') {
      domain.shift();
    }

    this._companyDomain = domain[0];
  }

  getCompanyInfo(): Observable<Company> {
    this.setCompanyDomain();

    return this.http.get(`${environment.companyInfo}/${this._companyDomain}`)
      .pipe(
        map(({data}: Pagination<Company>) => data[0])
      );
  }

  getApplicationInfo(): Observable<Application> {
    const guid = this.sharedFunctions.readQueryParameters('application');

    return this.http.get(`${environment.applications}/${guid}`)
      .pipe(
        map(({data}: Pagination<IApplication>) => new Application(data[0]))
      );
  }

  getKnockoutQuestions(): Observable<Quiz> {
    const guid = this.sharedFunctions.readQueryParameters('job');
    const language = this.translateService.currentLang;

    return this.http
      .get(`${environment.knockoutQuestions}/${guid}`, {
        headers: new HttpHeaders({ 'Accept-language': language }),
      })
      .pipe(
        map(({data}: Pagination<Quiz>) => data[0])
      );
  }

  getPersonalityTest(): Observable<PersonalityTest> {
    const guid = this.sharedFunctions.readQueryParameters('application');
    return this.http.get<PersonalityTest>(`${environment.psykometrika}/${guid}/test`);
  }

  getPersonalityTestResults(): Observable<PersonalityTestResults> {
    const guid = this.sharedFunctions.readQueryParameters('application');
    return this.http.get(`${environment.psykometrika}/${guid}/results`);
  }

  getPersonalityTestAnswers(): Observable<PersonalityTestAnswers> {
    const guid = this.sharedFunctions.readQueryParameters('application');
    return this.http.get<PersonalityTestAnswers>(`${environment.psykometrika}/${guid}/answers`);
  }
}
