import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class DocumentService {

  constructor(@Inject(DOCUMENT) private _doc: Document) {}

  get doc(): Document {
    return this._doc;
  }

  get window(): Window | null {
    return this._doc.defaultView;
  }

  get location(): Location {
    return this._doc.location;
  }

  get innerWidth(): number {
    return this.window.innerWidth || 0;
  }
}
