import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { slideInAnimation } from './resources/router-animations';
import { ActivatedRoute, NavigationExtras, NavigationStart, Params, Router, RouterOutlet } from '@angular/router';
import { catchError, filter, Observable, Subject, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { JobApplication } from './models/job-application.model';
import { AppConfigService } from './services/app-config.service';
import { LoaderService } from './services/loader.service';
import { CompanyService } from './services/company.service';
import { ErrorHandlingService } from './services/error-handling.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedFunctionsService } from './services/shared-functions.service';
import { DocumentService } from './services/window.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';

const DESKTOP_SCREEN_WIDTH = 1200;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit {

  activePage: string;

  showSendLinkContainer = false;
  showModal = false;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  // @HostListener('window:resize', ['$event.target'])
  // onResize({innerWidth}: Window): void {
  //   this.showSendLinkContainer =
  //     innerWidth > DESKTOP_SCREEN_WIDTH &&
  //     this.activePage === 'quiz'        &&
  //     !this.router.url.includes('ko');
  // }

  constructor(
    private appConfigService: AppConfigService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private errorHandlingService: ErrorHandlingService,
    private companyService: CompanyService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private sharedFunctions: SharedFunctionsService,
    private document: DocumentService,
    private configService: AppConfigService,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(({url}: NavigationStart) => {
        // this.handleRouteParams(url);

        if (url.includes('quiz') || url.includes('video')) {
          this.activePage = 'quiz';

          this.showSendLinkContainer =
            this.document.window.innerWidth > DESKTOP_SCREEN_WIDTH && !!this.sharedFunctions.readQueryParameters('application');
        } else if (
          url.includes('jobs')             ||
          url.includes('job-details')      ||
          url.includes('why-work-with-us') ||
          url.includes('express-interest') ||
          url.includes('saved-jobs')       ||
          url.includes('home')             ||
          url === '/'
        ) {
          this.activePage = 'company';
          this.showSendLinkContainer = false;
        } else if (
          url.includes('application-start')         ||
          url.includes('register')                  ||
          url.includes('registration-notification') ||
          url.includes('register-interest')
        ) {
          this.activePage = 'application';
          this.showSendLinkContainer = false;
        } else if (url.includes('application-complete')) {
          this.activePage = 'application-complete';
          this.showSendLinkContainer = false;
        } else {
          this.activePage = null;
          this.showSendLinkContainer = false;
        }
      });
  }

  ngOnInit(): void {
    const { company, jobApplication, inactiveJob } = this.appConfigService.config;

    this.renderer.setStyle(
      this.document.doc.documentElement,
      '--color-primary',
      company.primaryColor,
      2
    );

    if (company.useCompanyLandingPage) {
      this.renderer.setStyle(
        this.document.doc.documentElement,
        '--color-button-primary',
        company.companyLandingPage.buttonTextColor,
        2
      );
    }

    if (inactiveJob) {
      this.router.navigate(['/jobs']);
      this.toastr.error(
        this.translateService.instant('COMPANY.INACTIVE_JOB')
      );

      return;
    }


    // if (this.sharedFunctions.readQueryParameters('application')) {
    //   const activeModuleIndex = jobApplication.currentQuestionsModule;
    //   const activeModule = jobApplication.orderOfQuestionsModules[activeModuleIndex];
    //
    //   const queryParams: Params = { application: jobApplication.guid };
    //   const navExtras: NavigationExtras = { queryParams, queryParamsHandling: 'merge' };
    //
    //   const route = activeModule === 'businessQuestions' || activeModule === 'personalityTest'
    //     ? 'quiz'
    //     : 'video';
    //
    //   this.router.navigate([route], navExtras);
    // }
  }

  handleRouteParams(url: string): void {
    const currentLang = this.translateService.currentLang;
    const decodedURL = decodeURIComponent(url);

    if (decodedURL.includes(`/${currentLang}`)) {
      return;
    }

    const {urlWithoutParams, queryParams} = this.sharedFunctions.checkForQueryParams(decodedURL);

    const newUrl = `/${currentLang}${urlWithoutParams}`;
    this.router.navigate([newUrl], { queryParams, relativeTo: this.route});
  }

  prepareOutlet(outlet: RouterOutlet): RouterOutlet {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }

  // showHideSendLinkModal(): void {
  //   this.showModal = !this.showModal;
  // }

  // sendLink(): void {
  //   const guid = this.sharedFunctions.readQueryParameters('application');
  //
  //   if (!guid) {
  //     return;
  //   }
  //
  //   const jobApplication = this.appConfigService.config.jobApplication;
  //
  //   if (jobApplication.numberOfSentApplicationLinkSMSs >= 2) {
  //     this.toastr.error(
  //       this.translateService.instant('SEND_LINK.ERRORS.SENT_LINK_COUNT')
  //     );
  //     return;
  //   }
  //
  //   this.loaderService.show();
  //
  //   const language = this.translateService.currentLang || this.translateService.defaultLang || 'en';
  //
  //   this.companyService
  //     .sendSmsLink(guid, language)
  //     .pipe(
  //       catchError((errorResponse: HttpErrorResponse) => {
  //         const errorKeys = Object.keys(errorResponse.error.errors);
  //
  //         if (errorKeys.indexOf('application') >= 0) {
  //           this.toastr.error(errorResponse.error.errors.application);
  //           this.router.navigate(['/jobs']);
  //         } else {
  //           return this.errorHandlingService.handleBackendError(errorResponse);
  //         }
  //       })
  //     )
  //     .subscribe(({numberOfSentApplicationLinkSMSs}: JobApplication) => {
  //       jobApplication.numberOfSentApplicationLinkSMSs = numberOfSentApplicationLinkSMSs;
  //       const message = this.translateService.instant('SEND_LINK.TOAST_WILL_RECEIVE_SMS');
  //       this.toastr.success(message, 'Success');
  //       this.showHideSendLinkModal();
  //       this.loaderService.hide();
  //     });
  // }
}
