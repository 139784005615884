import { Injectable } from '@angular/core';
import { Job } from '../models/job.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SavedJobsService {

  private _savedJobsTotal$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  get savedJobsTotal$(): Observable<number> {
    return this._savedJobsTotal$.asObservable();
  }

  updateSavedJobsTotal(savedJobs: Job[]): void {
    const total = savedJobs.length;
    this._savedJobsTotal$.next(total > 0 ? total : null);
  }

  get savedJobs(): Job[] {
    return this.localStorage.getItem('savedJobs');
  }

  set savedJobs(savedJobs: Job[]) {
    this.localStorage.setItem('savedJobs', savedJobs);
  }

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private translateService: TranslateService,
    private localStorage: LocalStorageService
  ) {
    if (!this.savedJobs) {
      this.savedJobs = [];
    } else {
      // this.getAllCompanyJobs()
      //   .subscribe((jobs: Job[]) => {
      //     const existingSavedJobs = this.savedJobs.filter(savedJob => {
      //       const jobIndex = jobs.findIndex((job: Job) => savedJob.id === job.id);
      //       if (jobIndex >= 0) {
      //         savedJob.savedLocally = true;
      //         return savedJob;
      //       }
      //     });
      //     this.savedJobs = existingSavedJobs;
      //     this.updateSavedJobsTotal(this.savedJobs);
      //   });
    }
  }

  toggleJob(job: Job): void {
    const savedJobs = this.savedJobs;
    const savedJobIndex = this.savedJobs.findIndex((savedJob: Job) => savedJob.id === job.id);

    if (savedJobIndex > -1) {
      savedJobs.splice(savedJobIndex, 1);
    } else {
      job.savedLocally = true;
      savedJobs.push(job);
    }

    this.savedJobs = savedJobs;
    this.updateSavedJobsTotal(this.savedJobs);
  }

  mapSavedJobs(jobs: Job[]): void {
    if (this.savedJobs) {
      this.savedJobs.forEach((savedJob: Job) => {
        const localySavedJob = jobs.find((job: Job) => job.id === savedJob.id);
        if (localySavedJob) {
          localySavedJob.savedLocally = true;
        }
      });
    }
  }

  getAllCompanyJobs() {
    const language = this.translateService.currentLang;
    const companyGuid = this.configService.company.guid;
    const companyLanguage = this.configService.companyLanguage;
    let params = new HttpParams();
    params = params.set('limit', '1000');
    params = params.set('publishOnHigher', '1');
    params = params.set('status', 'active');
    params = params.set('language', companyLanguage);

    return this.http
      .get(`${environment.companyJobs}/${companyGuid}/jobs`, {
        headers: new HttpHeaders({ 'Accept-language': language }),
        params
      })
      .pipe(
        map((response: any) => response.data)
      );
  }
}
