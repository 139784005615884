import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NavigationStart, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { slideInAnimation } from '../../../resources/router-animations';
import { JobMode } from '../../../models/job-mode.model';
import { ErrorHandlingService } from '../../../services/error-handling.service';
import { AppConfigService } from '../../../services/app-config.service';
import { LoaderService } from '../../../services/loader.service';
import { CandidateRegisterService } from '../../../services/candidate-register.service';
import { Application } from '../../../classes/application.class';
import { SharedFunctionsService } from '../../../services/shared-functions.service';
import { SessionStorageService } from '../../../services/storage/session-storage.service';
import { CountryCallingCode } from '../../../models/country-calling-codes.model';
import { CompanyService } from '../../../services/company.service';

@Component({
  selector: 'app-candidate-register',
  templateUrl: './candidate-register.component.html',
  styleUrls: ['./candidate-register.component.scss']
})
export class CandidateRegisterComponent implements OnInit, OnDestroy {

  modalIsShown = false;

  countryCallingCodes: CountryCallingCode[];
  phoneNumberMaxLength: number;
  phoneNumberMinLength: number;
  companyCountryCode: CountryCallingCode;

  candidateRegisterForm = this.fb.group({
    name: ['', [Validators.required]],
    email: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[.][A-Za-z]{2,4}$'
        ),
      ],
    ],
    networks: [null, [Validators.required]],
    phone: [
      '',
      [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ],
    ],
    termsAndConditions: ['', [Validators.requiredTrue]],
  });

  termsAndConditions: string;

  universalJobId: number;
  mode: JobMode;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private configService: AppConfigService,
    private candidateService: CandidateRegisterService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private loaderService: LoaderService,
    private errorHandlingService: ErrorHandlingService,
    private translateService: TranslateService,
    private sharedFunctions: SharedFunctionsService,
    private sessionStorage: SessionStorageService,
    private companyService: CompanyService
  ) {
    this.router.events
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(({navigationTrigger}: NavigationStart) => {
        if (navigationTrigger === 'popstate' && this.mode === JobMode.regular) {
          this.sessionStorage.removeItem('answers');
          this.router.navigate(['job-details'], { queryParamsHandling: 'merge' });
        }
      });
  }

  get name(): FormControl {
    return this.candidateRegisterForm.get('name') as FormControl;
  }

  get email(): FormControl {
    return this.candidateRegisterForm.get('email') as FormControl;
  }

  get phone(): FormControl {
    return this.candidateRegisterForm.get('phone') as FormControl;
  }

  get networks(): FormControl {
    return this.candidateRegisterForm.get('networks') as FormControl;
  }

  ngOnInit(): void {
    const { company } = this.configService.config;

    this.getCountryCallingCodes(company.language);
    this.termsAndConditions = company.termsAndConditions;

    if (this.router.url.includes('register-interest')) {
      this.mode = JobMode.universal;
      this.universalJobId = company.universalJob.id;
    } else {
      this.mode = JobMode.regular;
      this.getJobId();
    }

  }

  getCountryCallingCodes(companyLanguage: string): void {
    this.loaderService.show();
    this.companyService.getCountryCallingCodes()
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe((countryCallingCodes: CountryCallingCode[]) => {
        this.countryCallingCodes = countryCallingCodes;

        this.companyCountryCode = this.countryCallingCodes.find(el => el.language === companyLanguage);
        this.updateNetwork();
        this.loaderService.hide();
      });
  }

  onSubmit(): void {
    const { invalid, value } = this.candidateRegisterForm;

    if (invalid) {
      return;
    }

    this.loaderService.show();
    const { name, email, networks, phone } = value;

    this.candidateService
      .registerCandidate({
        name,
        email,
        phone: networks.callingCode + phone,
        universalJobId: this.universalJobId,
      })
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        ),
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(({jobApplication, jobInfo}: Application) => {
        this.loaderService.hide();

        this.configService.config.jobApplication = jobApplication;
        this.configService.config.job = jobInfo;

        if (jobApplication.applicationComplete) {
          this.sessionStorage.removeItem('jobId');
          this.router.navigate(['/']);
          this.toastr.error(
            this.translateService.instant('ERRORS.ALREADY_FINISHED_APP')
          );

          return;
        }

        const route = jobApplication.continueApplication
          ? 'quiz'
          : 'application-start';

        this.router.navigate(
          [route],
          { queryParams: {application: jobApplication.guid}}
        );

        this.loaderService.hide();
      });
  }

  showHideModal(): void {
    this.modalIsShown = !this.modalIsShown;
  }

  iAgree(): void {
    this.showHideModal();
    this.candidateRegisterForm.get('termsAndConditions').setValue(true);
  }

  getJobId(): void {
    const jobId = this.sessionStorage.getItem('jobId');

    if (jobId) {
      return;
    }

    const { queryParams } = this.sharedFunctions.checkForQueryParams(decodeURIComponent(this.router.url));
    this.router.navigate(['/quiz'], { queryParams });
  }

  updateNetwork(): void {
    this.candidateRegisterForm.patchValue({networks: this.companyCountryCode});
    this.changedNetwork();
  }

  changedNetwork(): void {
    this.phoneNumberMaxLength = this.networks.value.maxLength;
    this.phoneNumberMinLength = this.networks.value.minLength;

    this.phone.setValidators([
      Validators.minLength(this.phoneNumberMinLength),
      Validators.maxLength(this.phoneNumberMaxLength),
      Validators.required,
      Validators.pattern('^[1-9][0-9]*$')
    ]);
    this.phone.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}

