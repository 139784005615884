<div *ngIf="modalIsShown"
     (click)="hideModal()"
     class="modal-window-overlay"></div>
<div *ngIf="modalIsShown"
     class="modal-window">
  <div class="modal-window-header">
    <ng-content select=".modal-window-title"></ng-content>
    <button type="button"
            (click)="hideModal()">X</button>
  </div>
  <div class="modal-window-body">
    <ng-content select=".modal-window-content"></ng-content>
  </div>
  <div class="modal-window-footer">
    <ng-content select=".modal-footer-content"></ng-content>
  </div>
</div>
