// field iso6391 is language code according to ISO639-1 standard and uses 2 letters
// field iso6392 is language code according to ISO639-2 standard and uses 3 letters
// array items are using format defined in LangData interface
export const LANGUAGES = [
  {
    name: {
      en: 'Sweedish',
      sv: 'Svenska',
      no: 'Svensk'
    },
    iso6391: 'sv',
    iso6392: 'swe'
  },
  {
    name: {
      en: 'Norwegian',
      sv: 'Norska',
      no: 'Norsk',
    },
    iso6391: 'no',
    iso6392: 'no'
  },
  {
    name: {
      en: 'English',
      sv: 'Engelsk',
      no: 'Engelsk',
    },
    iso6391: 'en',
    iso6392: 'eng'
  }
];
