import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { HeaderComponent } from './header/header.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RouterModule } from '@angular/router';
import { routes } from '../../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { LanguageSelectComponent } from './header/language-select/language-select.component';

@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    ProgressBarComponent,
    LanguageSelectComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule
  ],
  exports: [
    LoaderComponent,
    HeaderComponent,
    ProgressBarComponent
  ]
})
export class LayoutModule { }
