import { LangData } from '../../../../models/lang-data.model';
import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LANGUAGES } from '../../../../resources/languages';
import { TranslateService } from '@ngx-translate/core';
import { SharedFunctionsService } from '../../../../services/shared-functions.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {

  activeLanguage: string;
  nativeLanguage: LangData;

  readonly availableLanguages = LANGUAGES;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private configService: AppConfigService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedFunctions: SharedFunctionsService
  ) { }

  ngOnInit(): void {
    this.activeLanguage = this.translateService.currentLang;
    this.nativeLanguage = LANGUAGES.find(lang => {
      if (lang.iso6391 === this.configService.companyLanguage) {
        return lang;
      }
    });
  }

  changeLanguage(langCode: string): void {
    const url = this.router.url.replace(`/${this.activeLanguage}`, `/${langCode}`);
    this.activeLanguage = langCode;

    const {urlWithoutParams, queryParams} = this.sharedFunctions.checkForQueryParams(url);

    this.translateService.use(langCode)
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(() => {
        this.router.navigate([urlWithoutParams], { queryParams, relativeTo: this.route});
      });
  }
}
