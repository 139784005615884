// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { apiVersion } from './api-version';
import { endpoints } from './endpoints';

const stagingUrl = 'https://api.higher.soprexdev.com';

const EXTERNAL_DOMAINS = [
  {
    externalDomain: 'careers.soprexdev.com',
    companyDomain: 'demo'
  }
];

export const environment = {
  production: false,
  name: 'dev',
  domain: 'higher.soprexdev',
  externalDomains: EXTERNAL_DOMAINS,
  mediaServerUrl: 'wcs.soprexdev.com',
  ...endpoints(stagingUrl, apiVersion)
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
