import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss']
})
export class ModalWindowComponent {

  @Input() modalIsShown: boolean;
  @Output() hideModalWindow = new EventEmitter<boolean>();

  hideModal(): void {
    this.hideModalWindow.emit(true);
  }

}
