import { Injectable } from '@angular/core';
import { DocumentService } from './window.service';
import { PersonalityTestContent } from '../models/personality-test.model';
import { Question } from '../models/quiz.model';
import { QUIZ_TYPES } from '../resources/quiz-types';

@Injectable({ providedIn: 'root' })
export class SharedFunctionsService {

  constructor(
    private document: DocumentService
  ) {
  }

  readQueryParameters(paramName: string): string {
    const url = decodeURIComponent(this.document.location.href);
    const {queryParams} = this.checkForQueryParams(url);

    return queryParams[paramName] || '';
  }

  checkForQueryParams(url: string): {urlWithoutParams: string, queryParams: any} {
    const questionMarkIndex = url.indexOf('?');
    const ampersandIndex = url.indexOf('&');
    let urlWithoutParams = url;
    const queryParams = {};

    if (questionMarkIndex >= 0) {
      urlWithoutParams = url.slice(0, questionMarkIndex);
      const endIndex = ampersandIndex >= 0 ? ampersandIndex : undefined;
      const queryUrl = url.slice(questionMarkIndex + 1, endIndex);
      const query = queryUrl.split('=');
      const paramName = query[0];
      queryParams[paramName] = query[1];
    }

    return { urlWithoutParams, queryParams };
  }

  formatPersonalityTestQuestions(questionsResponse: PersonalityTestContent): Question[] {
    const questionGroups = Object.keys(questionsResponse);
    const personalityTest: Question[] = [];

    questionGroups.forEach((groupUUID: string) => {
      questionsResponse[groupUUID].forEach(question => {
        personalityTest.push({
          id: question.id,
          uuid: groupUUID,
          text: question.text
        });
      });
    });
    return personalityTest;
  }

  setQuizTypeQueryParam(quizType: string): 'bq' | 'pt' | 'kq' {

    if (quizType === QUIZ_TYPES.BUSINESS_QUESTIONS) {
      return 'bq';
    } else if (quizType === QUIZ_TYPES.PERSONALITY_TEST) {
      return 'pt';
    } else if (quizType === QUIZ_TYPES.KNOCKOUT_QUESTIONS) {
      return 'kq';
    }

  }

  convertStringDateToJSDate(date: string): Date {
    const newDate = date.split('.');
    return new Date(+newDate[2], +newDate[1] - 1, +newDate[0]);
  }
}
