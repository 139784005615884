import { Injectable } from '@angular/core';
import { AppConfig } from '../models/app-config.model';
import { SetupService } from './setup.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Company } from '../models/company.model';
import { forkJoin, of, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Application } from '../classes/application.class';
import { LANGUAGES } from '../resources/languages';
import { TranslateService } from '@ngx-translate/core';
import { SharedFunctionsService } from './shared-functions.service';
import { DocumentService } from './window.service';

@Injectable({ providedIn: 'root' })
export class AppConfigService extends SetupService {

  private _config: AppConfig;

  constructor(
    http: HttpClient,
    translateService: TranslateService,
    sharedFunctions: SharedFunctionsService,
    document: DocumentService,
  ) {
    super(http, translateService, sharedFunctions, document);
  }

  get config(): AppConfig {
    return this._config;
  }

  get company(): Company {
    return this._config.company;
  }

  get companyLanguage(): string {
    return this.company.language;
  }

  initialize(): Observable<boolean> {
    const applicationInfo$: Observable<Application> = this.sharedFunctions.readQueryParameters('application')
      ? this.getApplicationInfo()
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.error.errors.job === 'Job is not active anymore.') {
              // window.location.href = `${window.location.origin}/jobs`;
              return of({jobInfo: null, jobApplication: null, inactiveJob: true});
            }

            return throwError(() => error);
          })
        )
      : of({jobInfo: null, jobApplication: null});

    return forkJoin([
      this.getCompanyInfo(),
      applicationInfo$
    ]).pipe(
        map(([company, {jobInfo, jobApplication, inactiveJob}]) => {
          this._config = {
            company,
            job: jobInfo,
            jobApplication,
            inactiveJob
          };
          this.setLanguage(company);
          return true;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 403 || error.status === 404) {
            this.document.location.href = 'https://www.uhigher.se';
          }

          throwError(() => error);
          return of(false);
        }),
      );
  }

  setLanguage(company: Company): void {
    const path = this.document.location.pathname;
    const language = LANGUAGES.find(lang => path.includes(`/${lang.iso6391}`))?.iso6391;

    this.translateService.use(language || company.candidatePortalLanguage || company.language);
  }
}
