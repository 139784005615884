import { ToastrService } from 'ngx-toastr';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Router, Event, NavigationStart } from '@angular/router';
import { QuizStoreService } from 'src/app/services/quiz-store.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SavedJobsService } from 'src/app/services/saved-jobs.service';
import { Company } from 'src/app/models/company.model';
import { DocumentService } from 'src/app/services/window.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild('infoPopup') popup: ElementRef;
  @ViewChild('popupToggleButton') popupToggleButton: ElementRef;
  @ViewChild('popupToggleButtonIcon') popupToggleButtonIcon: ElementRef;

  company: Company;
  savedJobsTotal: number;

  showInfoButton = false;
  showApplyButton = false;
  showProgressBar = false;
  popupActive = false;
  popupContentTranslationKey = '';

  showNavigationContainer = false;
  showSocialMediaLinks = false;
  showJobListButton = false;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  private readonly _rendererListener: any;

  constructor(
    private configService: AppConfigService,
    private router: Router,
    private quizStore: QuizStoreService,
    private renderer: Renderer2,
    private savedJobsService: SavedJobsService,
    private cdr: ChangeDetectorRef,
    // private clipboardService: ClipboardService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private sharedFunctions: SharedFunctionsService,
    private document: DocumentService
  ) {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationStart),
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(({url}: NavigationStart) => {
        this.showApplyButton = url.includes('/job-details');
        this.showJobListButton = url.includes('/application-complete');

        this.toggleInfoButton(url);
        this.toggleProgressBar(url);
        this.toggleNavigation(url);
      });

    this._rendererListener = this.renderer.listen('window', 'click', (e) => {
      if (this.popupToggleButton && this.popup && this.popupToggleButtonIcon) {
        if (
          e.target !== this.popupToggleButton.nativeElement &&
          e.target !== this.popup.nativeElement &&
          e.target !== this.popupToggleButtonIcon.nativeElement
        ) {
          this.popupActive = false;
        }
      }
    });
  }

  ngOnInit(): void {
    // this.savedJobsService.savedJobsTotal$
    //   .pipe(
    //     takeUntil(this._ngUnsubscribe$)
    //   )
    //   .subscribe(total => this.savedJobsTotal = total);

    this.company = this.configService.company;

    this.showSocialMediaLinks = !!this.company.facebookProfileUrl  ||
                                !!this.company.instagramProfileUrl ||
                                !!this.company.linkedInProfileUrl;

    const url = this.document.location.href;
    this.toggleNavigation(url);
    this.showApplyButton = url.includes('/job-details');
  }

  setPopupContent(url: string): void {
    if (url.includes('/quiz')) {
      if (this.quizStore.quiz.type !== 'personalityTest') {
        this.popupContentTranslationKey = 'DEFAULT';
      } else {
        this.popupContentTranslationKey = 'PERSONALITY_TEST';
      }
    } else if (url.includes('/video')) {
      this.popupContentTranslationKey = 'VIDEO_QUESTIONS';
    }
  }

  toggleInfoButton(url: string): void {
    const quizType = this.quizStore.quiz?.type;

    this.showInfoButton = (url.includes('/quiz') && quizType !== 'knockoutQuestions') ||
                           url.includes('/video');
  }

  toggleProgressBar(url: string): void {
    this.showProgressBar = url.includes('/quiz')                ||
                           url.includes('/video')               ||
                           url.includes('quiz/message')         ||
                           url.includes('application-complete') ||
                           url.includes('application-start');
  }

  toggleNavigation(url: string): void {
    this.showNavigationContainer = !(url.includes('/quiz')                ||
                                     url.includes('/video')               ||
                                     url.includes('quiz/message')         ||
                                     url.includes('application-complete') ||
                                     url.includes('application-start')    ||
                                     url.includes('job-details'));
  }

  toggleInfoPopup(): void {
    this.popupActive = !this.popupActive;
    const { type } = this.quizStore.quiz;

    if (type === 'personalityTest') {
      this.popupContentTranslationKey = 'INFO_POPUP.PERSONALITY_TEST';
    } else if (type === 'videoQuestions') {
      this.popupContentTranslationKey = 'INFO_POPUP.VIDEO_QUESTIONS';
    } else if (type === 'businessQuestions') {
      this.popupContentTranslationKey = 'INFO_POPUP.DEFAULT';
    }
    this.cdr.detectChanges();
  }

  share(): void {
    // this.clipboardService.copyFromContent(this.document.location.toString());
    this.toastr.success(this.translateService.instant('HEADER.LINK_COPIED'));
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
    this._rendererListener();
  }
}
