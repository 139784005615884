import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
// import { CandidateNotificationComponent } from './candidate-notification/candidate-notification.component';
// import { ApplicationStartComponent } from './application-start/application-start.component';
// import { ApplicationCompleteComponent } from './application-complete/application-complete.component';
import { CustomReuseStrategy } from './resources/route-reuse-strategy';
import { AppGuard } from './guards/app.guard';
import { CandidateRegisterComponent } from './modules/shared/candidate-register/candidate-register.component';

export const routes: Routes = [
  {
    canActivate: [AppGuard],
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule),
      },
      {
        path: 'quiz',
        loadChildren: () => import('./modules/quiz/quiz.module').then(m => m.QuizModule),
      },
      // {
      //   path: 'video',
      //   loadChildren: () => import('./modules/video-questions/video-questions.module').then(m => m.VideoQuestionsModule)
      // },
      // {
      //   component: ApplicationStartComponent,
      //   path: 'application-start',
      //   data: { reuseRoute: false },
      // },
      // {
      //   component: ApplicationCompleteComponent,
      //   path: 'application-complete',
      //   data: { reuseRoute: false },
      // },
      // {
      //   component: CandidateNotificationComponent,
      //   path: 'registration-notification',
      //   data: { reuseRoute: false },
      // },
      {
        component: CandidateRegisterComponent,
        path: 'register',
        data: { reuseRoute: false },
      },
      {
        component: CandidateRegisterComponent,
        path: 'register-interest',
        data: { reuseRoute: false }
      },
      {
        path: '**',
        redirectTo: 'jobs'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabledBlocking' })],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
