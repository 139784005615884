import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TrimValueAccessorDirective } from './directives/trim.directive';
import { ModalWindowComponent } from './modal-window/modal-window.component';
import { FooterComponent } from './footer/footer.component';
import { CandidateRegisterComponent } from './candidate-register/candidate-register.component';
import { QuillModule } from 'ngx-quill';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    TrimValueAccessorDirective,
    ModalWindowComponent,
    FooterComponent,
    CandidateRegisterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    QuillModule
  ],
  exports: [
    TranslateModule,
    QuillModule,
    TrimValueAccessorDirective,
    ModalWindowComponent,
    FooterComponent,
    CandidateRegisterComponent
  ]
})
export class SharedModule { }
