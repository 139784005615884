<header>
  <div class="company-logo" routerLink="/home">
    <img [src]="company.companyLogo" alt="Company logo"/>
  </div>

  <nav *ngIf="showNavigationContainer">

    <input type="checkbox"
           id="nav-toggle"
           class="navigation-toggle"
           #navToggleInput
           hidden>

    <label for="nav-toggle" class="nav-toggle-label">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </label>

    <div class="navigation-content">
      <div class="nav-links">
        <a class="navigation-link"
           routerLink="/jobs"
           routerLinkActive="active"
           (click)="navToggleInput.checked = false">{{ 'HEADER.ALL_JOBS' | translate }}</a>
        <a class="navigation-link"
           routerLink="/why-work-with-us"
           routerLinkActive="active"
           (click)="navToggleInput.checked = false">{{ 'HEADER.WHY_WORK_WITH_US' | translate }}</a>
        <span class="saved-jobs-desktop">
          <ng-container [ngTemplateOutlet]="savedJobs"></ng-container>
        </span>
      </div>

      <div class="nav-links social-media-links" *ngIf="showSocialMediaLinks">
        <a [href]="company.facebookProfileUrl"
           *ngIf="company.facebookProfileUrl"
           target="_blank"
           class="navigation-link">
          <i class="icon-facebook-plain"></i>
        </a>
        <a [href]="company.instagramProfileUrl"
           *ngIf="company.instagramProfileUrl"
           target="_blank"
           class="navigation-link">
          <i class="icon-instagram-plain"></i>
        </a>
        <a [href]="company.linkedInProfileUrl"
           *ngIf="company.linkedInProfileUrl"
           target="_blank"
           class="navigation-link">
          <i class="icon-linkedin-plain"></i>
        </a>
        <span (click)="share()"
              class="navigation-link">
          <i class="icon-share"></i>
        </span>
      </div>

      <div class="nav-links language-select">
        <app-language-select></app-language-select>
      </div>

      <span class="saved-jobs-mobile">
        <ng-container [ngTemplateOutlet]="savedJobs"></ng-container>
      </span>

    </div>
  </nav>

  <div *ngIf="showApplyButton"
       class="apply-now">
    <a routerLink="/quiz"
       [queryParamsHandling]="'merge'"
       class="btn-primary">{{ 'HEADER.APPLY_NOW' | translate }}</a>
  </div>

</header>

<ng-template #savedJobs>
  <a class="navigation-link saved-jobs-link"
     [routerLink]="savedJobsTotal === 0 ? '/express-interest' : '/saved-jobs'">
    <div class="saved-jobs-indicator">
      <i [attr.data-content]="savedJobsTotal"
         [ngClass]="{
           'icon-icon-heart': !!savedJobsTotal,
           'icon-heart-outline': !savedJobsTotal,
           'jobs-count': !!savedJobsTotal
         }">
      </i>
    </div>
  </a>
</ng-template>
