export function endpoints(url: string, apiVersion: string) {
  return {
    candidates:                `${url}/${apiVersion}/candidates`,
    companyInfo:               `${url}/${apiVersion}/companies/context/company_landing_page`,
    companyJobs:               `${url}/${apiVersion}/companies`,
    companyCategories:         `${url}/${apiVersion}/job_categories`,
    companyLocations:          `${url}/${apiVersion}/locations`,
    companyTypesOfEmployment:  `${url}/${apiVersion}/job_types_of_employment`,
    companyTimeExtents:        `${url}/${apiVersion}/job_time_extents`,
    job:                       `${url}/${apiVersion}/jobs/page`,
    jobs:                      `${url}/${apiVersion}/jobs`,
    knockoutQuestions:         `${url}/${apiVersion}/knockout_questions`,
    businessQuestions:         `${url}/${apiVersion}/business_questions`,
    videoQuestions:            `${url}/${apiVersion}/video_questions`,
    createApplication:         `${url}/${apiVersion}/applications`,
    applications:              `${url}/${apiVersion}/applications/page`,
    psykometrika:              `${url}/${apiVersion}/psykometrika`,
    video:                     `${url}/${apiVersion}/video_questions`
  };
}
