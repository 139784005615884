export interface JobFilters {
  category: string;
  typeOfEmployment: string;
  location: string;
  timeExtent?: string;
  searchBy?: string;
  page?: number;
}

export type FilterType = 'category' | 'typeOfEmployment' | 'location';

export const INITIAL_JOB_FILTERS = { location: null, typeOfEmployment: null, category: null, searchBy: null };
