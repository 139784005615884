import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Quiz, Question } from '../models/quiz.model';
import { SessionStorageService } from './storage/session-storage.service';

@Injectable({ providedIn: 'root' })
export class QuizStoreService {

  private _quiz: BehaviorSubject<Quiz> = new BehaviorSubject<Quiz>(null);

  constructor(
    private sessionStorage: SessionStorageService
  ) { }

  get quiz(): Quiz {
    return this._quiz.getValue();
  }

  set quiz(data: Quiz) {
    this._quiz.next(data);
  }

  updateQuestionAnswer(questionIndex: number, answerIndex: number): void {
    const quiz = { ...this.quiz };
    quiz.questions[questionIndex].selectedAnswer = answerIndex;
    this.quiz = { ...quiz };
  }

  // use this to figure out which question to show if user is trying to continue where he left off
  setActiveQuestionIndex(activeQuestionIndex: number): void {
    const quiz = {
      ...this.quiz,
      activeQuestionIndex: activeQuestionIndex
    };
    this.quiz = { ...quiz };
  }

  getActiveQuestionIndex(): number {
    const quiz = { ...this.quiz };
    return quiz.activeQuestionIndex;
  }

  reset(): void {
    this.quiz = null;
  }

  saveKnockoutQuestions(): void {
    const quiz = { ...this.quiz };
    const answers: number[][] = [];
    quiz.questions.forEach((question: Question, index: number) => {
      answers[index] = [question.selectedAnswer];
    });
    this.sessionStorage.setItem('answers', answers);
  }
}
