import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AppConfigService } from './app-config.service';
import {
  CreateCandidateResponse,
  RegisterCandidatePayload,
} from '../models/candidate.model';
import { TranslateService } from '@ngx-translate/core';
import { IApplication } from '../models/application.model';
import { Application } from '../classes/application.class';
import { CustomEncoder } from '../classes/encoder.class';
import { Pagination } from '../models/pagination.interface';

@Injectable({
  providedIn: 'root',
})
export class CandidateRegisterService {

  applyingForUniversalJob: boolean;
  continuingApplication = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private configService: AppConfigService,
    private translateService: TranslateService
  ) {}

  registerCandidate(candidate: {
    name: string;
    email: string;
    phone: string;
    universalJobId: number;
  }): Observable<Application> {
    return this.createCandidate(candidate.email)
      .pipe(
        mergeMap((createCandidateResponse: CreateCandidateResponse) => {
          return this.createApplication(
            createCandidateResponse,
            candidate.name,
            candidate.phone,
            candidate.universalJobId
          );
        }),
        mergeMap((createJobApplicationResponse: IApplication) => {
          const answers = JSON.parse(sessionStorage.getItem('answers'));
          if (!this.applyingForUniversalJob && answers != null && !this.continuingApplication) {
            return this.submitKnockoutQuestionsAnswers(
              createJobApplicationResponse.guid,
              answers
            ).pipe(
              map(() => {
                return new Application(createJobApplicationResponse);
              })
            );
          } else {
            this.continuingApplication = false;
            return of(
              new Application(createJobApplicationResponse)
            );
          }
        })
    );
  }

  createCandidate(email: string): Observable<CreateCandidateResponse> {
    const companyID = this.configService.config.company.id;
    const companyGUID = this.configService.config.company.guid;

    return this.http
      .post(
        `${environment.candidates}/${companyGUID}`,
        {
          email,
          company: companyID,
        }
      )
      .pipe(
        map(({data}: Pagination<CreateCandidateResponse>) => data[0])
      );
  }

  createApplication(
    {id, guid, email}: CreateCandidateResponse,
    candidateAppliedWithName: string,
    phoneNumber: string,
    universalJobId: number
  ): Observable<IApplication> {
    const language = this.translateService.currentLang;
    const payload: RegisterCandidatePayload = {
      candidate: id,
      candidateAppliedWithName,
      phoneNumber,
    };

    if (universalJobId) {
      payload.universalJob = universalJobId;
      this.applyingForUniversalJob = true;
    } else {
      payload.job = Number(sessionStorage.getItem('jobId'));
      this.applyingForUniversalJob = false;
    }

    return this.http
      .post(
        `${environment.createApplication}/${guid}`,
        payload,
        {
          headers: new HttpHeaders({ 'Accept-language': language }),
        }
      )
      .pipe(
        map(({data}: Pagination<IApplication>) => data[0]),
        catchError((errorResponse: HttpErrorResponse) => {
          if (
            errorResponse.status === 409
          ) {
            this.continuingApplication = true;
            return this.continueApplication(payload.universalJob, payload.job, email);
          } else {
            sessionStorage.removeItem('jobId');
            this.router.navigate(['/']);
            return throwError(() => errorResponse);
          }
        })
      );
  }

  submitKnockoutQuestionsAnswers(
    jobApplicationGUID: string,
    answers: [[number]]
  ): Observable<string> {
    return this.http.put<string>(
      `${environment.knockoutQuestions}/${jobApplicationGUID}`,
      { questions: answers }
    );
  }

  continueApplication(universalJob: number, jobId: number, candidateEmail: string): Observable<IApplication> {
    let params = new HttpParams({encoder: new CustomEncoder()});
    if (universalJob) {
      params = params.append('universalJob', `${universalJob}`);
    }
    if (jobId) {
      params = params.append('jobIds', `${jobId}`);
    }
    params = params.append('candidateEmail', candidateEmail);
    return this.http.get(environment.createApplication, { params }).pipe(
      map(({data}: Pagination<IApplication>) => data[0]),
      map((application) => {
        return { ...application, continueApplication: true};
      })
    );
  }
}
