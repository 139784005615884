import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentService } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private _loaderStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    private document: DocumentService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  get loader(): Observable<boolean> {
    return this._loaderStatus$.asObservable();
  }

  show(): void {
    this.renderer.addClass(this.document.doc.querySelector('app-root'), 'loader-active');
    this._loaderStatus$.next(true);
  }

  hide(): void {
    this.renderer.removeClass(this.document.doc.querySelector('app-root'), 'loader-active');
    this._loaderStatus$.next(false);
  }

}
